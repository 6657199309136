import * as React from "react";
import {createGlobalStyle} from "styled-components";
import AppView from "../components/AppView";
import {Helmet} from "react-helmet";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #010101;
    margin: 0;
    overflow: hidden;
    font-family: monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
`

const IndexPage = () => {
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet={'utf-8'}/>
                <html lang={'en'}/>
                <title>Kaijah's Webzone</title>
            </Helmet>
            <GlobalStyle/>
            <AppView/>
        </React.Fragment>
    )
}

export default IndexPage;