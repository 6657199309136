import * as React from "react";
import {NormalSpan, SmallSpan} from "./LandingSpan";
import {KeyboardEventHandler} from "react";

interface LandingPageArgs {
    glitched: boolean,
    name: String,
    ascii: String,
    onClick: () => void,
}

const LandingView = ({glitched, name, ascii, onClick}: LandingPageArgs) => {
    const handleEnterPress: KeyboardEventHandler<HTMLSpanElement> = (e) => {
        if (e.key === 'Enter') {
            onClick();
        }
    }

    return (
        <>
            <NormalSpan glitched={glitched} data-text={name}>{name}</NormalSpan>
            <SmallSpan
                glitched={glitched}
                onClick={onClick}
                onKeyPress={handleEnterPress}
                data-text={ascii}
                tabIndex={0}
            >{ascii}</SmallSpan>
        </>
    )
}

export {
    LandingView,
}