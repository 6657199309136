import * as React from "react";
import {useState} from "react";
import {TerminalView} from "./TerminalView";
import {LandingView} from "./LandingView";
import styled from "@emotion/styled";

const rabbit = `   /\\ /|
   \\ V/
   | "")
   /  |
  /  \\\\
*(__\\_\\
`

const name = 'KAIJAH';

const AppLayout = styled.div`
    overflow: hidden;
`

const AppHeader = styled.header`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 1);  
  overflow: hidden;
`

function AppView() {
    const [terminalOpen, setTerminalOpen] = useState(false);
    const [glitched, setGlitched] = useState(false);

    const openTerminal = () => {
        setGlitched(true);
        setTimeout(() => {
            setTerminalOpen(true);
        }, 200);
    }

    const closeTerminal = () => {
        setTimeout(() => {
            setTerminalOpen(false);
            setGlitched(false);
        }, 500)
    }

    return (
        <AppLayout>
            <AppHeader>
                <LandingView glitched={glitched} name={name} ascii={rabbit} onClick={openTerminal}/>
                {terminalOpen && <TerminalView exitHandler={closeTerminal}/>}
            </AppHeader>
        </AppLayout>
    );
}

export default AppView;


