import * as React from "react";
import styled from "@emotion/styled";
import {css, keyframes} from "@emotion/react";

interface LandingArgs {
    glitched: boolean,
}

const baseCss = css`
  color: white;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  text-align: center;
`

const SmallSpan = styled.span`
  ${baseCss};
  
  padding: 5px;
  font-size: max(min(1vw, 12px), 6px);
  font-weight: 900;
  white-space: pre;
  background: none;
  border: none;
  
  :focus-visible {
    outline-width: 2px;
    outline-style: dashed;
  }

  ${(props: LandingArgs) => props.glitched && smallPseudo};
`

const NormalSpan = styled.span`
  ${baseCss};
  
  font-size: min(10vw, 120px);
  
  ${(props: LandingArgs) => props.glitched && normalPseudo};
`

const normalGlitch = keyframes`
  50% {
    left: 0;
    text-shadow: -2px 2px 1px #00d9ff, -2px -2px 1px #ff00fb;
    clip: rect(min(3vw, calc(0.3 * 100px)), 9999px, min(8vw, calc(0.8 * 100px)), 0);
  }

  100% {
    left: -0.1vw;
    text-shadow: -2px 2px 1px #00d9ff, -2px -2px 1px #ff00fb;
    clip: rect(min(4vw, calc(0.4 * 100px)), 9999px, min(8vw, calc(0.8 * 100px)), 0);
  }
`

const smallGlitch = keyframes`
  25% {
    top: -0.1vw;
    left: 0.25vw;
    text-shadow: -2px 2px 1px #00d9ff;
  }

  50% {
    top: 0;
    left: 0;
    text-shadow: -2px 2px 1px #00d9ff, -2px -2px 1px #ff00fb;
  }

  75% {
    top: 0.1vw;
    left: -0.25vw;
    text-shadow: -2px 2px 1px #00d9ff, -2px -2px 1px #ff00fb;
  }

  100% {
    top: -0.2vw;
    left: 0.1vw;
    text-shadow: -2px 2px 1px #00d9ff, -2px -2px 1px #ff00fb;
  }
`

const smallPseudo = css`
  ::before,
  ::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    width: 100%;
    height: 100%;
    background: none;
    animation: ${smallGlitch} 0.2s forwards step-end;
  }
`

const normalPseudo = css`
  ::before,
  ::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    animation: ${normalGlitch} 0.2s forwards step-end;
  }
`

export {
    SmallSpan,
    NormalSpan,
}