import * as React from "react";
import Terminal from "react-console-emulator";
import styled from "@emotion/styled";
import {keyframes} from "@emotion/react";
import {useRef, useState} from "react";
import {isMobile} from "react-device-detect";

interface TerminalArgs {
    exitHandler: () => void,
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  60% {
    opacity: 25%;
  }

  80% {
    opacity: 0;
  }

  90% {
    opacity: 75%;
  }

  100% {
    opacity: 100%;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 100%;
  }

  60% {
    opacity: 75%;
  }

  80% {
    opacity: 100%;
  }

  90% {
    opacity: 25%;
  }

  100% {
    opacity: 0;
  }
`

const TerminalContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
`

const TerminalWrapper = styled.div`
  width: min(100vw, 800px);
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.9);
  border-left: 1px solid #FFFFFF05;
  border-right: 1px solid #FFFFFF05;
  overflow: hidden;
  animation-name: ${(props: { exiting: boolean }) => props.exiting ? fadeOut : fadeIn};
  animation-fill-mode: forwards;
  animation-duration: 500ms;
  animation-timing-function: step-end;

  > div {
    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      background: black;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #333333;
      border: 3px solid black;
    }

    scrollbar-width: thin;
    scrollbar-color: #333333 black;
  }
`

const TerminalView = ({exitHandler}: TerminalArgs) => {
    const terminal = useRef<Terminal>(null);
    const [exiting, setExiting] = useState(false);
    const commands = {
        about: {
            description: 'Show a short summary.',
            fn: () => {
                if (isMobile) terminal.current?.terminalInput.current?.blur();

                return [
                    '------\n',
                    'Experienced Senior Software Engineer with proven track record of collaborating closely with ' +
                    'designers, stakeholders, and cross-functional teams to create innovative applications. Adept at ' +
                    'empowering and working alongside fellow engineers to cultivate a thriving team culture. Strong ' +
                    'background in full-stack development, proficient in TypeScript and Rust, and experienced with ' +
                    'serverless architecture. Skilled in  crafting end-user and internal applications, delivering ' +
                    'scalable, maintainable solutions that prioritize performance and developer experience. ' +
                    'Passionate about distributed systems, event-driven architecture, and embracing emerging ' +
                    'technologies.\n' +
                    '------\n',
                ];
            }
        },
        history: {
            description: 'Show the history information.',
            fn: () => {
                if (isMobile) terminal.current?.terminalInput.current?.blur();

                return [
                    '------\n',
                    'Senior Full-Stack Software Engineer\n',
                    'EMCEE\n',
                    'Jun 2023 -> Current\n',
                    '------\n',
                    'Senior Software Engineer\n',
                    'FloorFound\n',
                    'Jul 2022 -> May 2023\n',
                    '------\n',
                    'Software Engineer II\n',
                    'Feather\n',
                    'Oct 2021 -> Jun 2022\n',
                    '------\n',
                    'Senior Software Engineer\n',
                    'TrainerRoad\n',
                    'Mar 2020 -> Aug 2020\n',
                    '------\n',
                    'Software Engineer\n',
                    'Synap\n',
                    'Feb 2017 -> Mar 2020\n',
                    '------\n',
                ];
            },
        },
        skills: {
            description: 'Show a list of skills.',
            fn: () => {
                if (isMobile) terminal.current?.terminalInput.current?.blur();

                return [
                    '------\n',
                    'LANGUAGES\n',
                    'TypeScript\n',
                    'Javascript\n',
                    'Rust\n',
                    'Ruby\n',
                    'GraphQL\n',
                    '------\n',
                    'FRAMEWORKS\n',
                    'React\n',
                    'NestJS\n',
                    'Next.js\n',
                    'Ruby on Rails\n',
                    '------\n',
                    'ARCHITECTURE\n',
                    'Serverless\n',
                    'AWS Lambdas\n',
                    'AWS Step Functions\n',
                    'Apache Kafka\n',
                    'Temporal.io\n',
                    '------\n',
                    'INFRASTRUCTURE\n',
                    'AWS\n',
                    'GCP\n',
                    'Docker\n',
                    'Kubernetes\n',
                    'Terraform\n',
                    '------\n',
                    'DATABASES\n',
                    'DynamoDB\n',
                    'PostgreSQL\n',
                    'MySQL\n',
                    '------\n',
                ]
            },
        },
        exit: {
            description: 'Disconnect from the terminal.',
            fn: () => {
                if (isMobile) terminal.current?.terminalInput.current?.blur();

                terminal.current?.clearStdout();
                setExiting(true);
                exitHandler();

                return [
                    'Disconnecting... Goodbye!',
                ];
            },
        },
    }

    return (
        <TerminalContainer>
            <TerminalWrapper
                exiting={exiting}
            >
                <Terminal
                    ref={terminal}
                    commands={commands}
                    autoFocus={true}
                    style={{
                        backgroundColor: 'none',
                        fontSize: '16px',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        scrollbarColor: 'dark',
                    }}
                    contentStyle={{color: '#73defd', fontSize: '16px',}}
                    promptLabelStyle={{
                        paddingTop: 0,
                        color: '#f500f1',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: '21px',
                    }}
                    inputStyle={{paddingTop: 0, height: 'auto',}}
                    inputTextStyle={{
                        color: '#73defd',
                        padding: '0 0 0 9px',
                        fontSize: '16px',
                        lineHeight: '21px',
                    }}
                    styleEchoBack={'labelOnly'}
                    welcomeMessage={[
                        'Connecting... Success!\n',
                        'Running Kai Info Terminal OS v2023.11',
                    ]}
                    promptLabel={'anonymous@KIT:~$'}
                    errorText={'Command not found! Try help to see a list of commands.'}
                    disabled={exiting}
                    ignoreCommandCase={true}
                />
            </TerminalWrapper>
        </TerminalContainer>
    )
}

export {
    TerminalView,
}